import React from 'react';
import appConfigs from '../../../app.settings';

export const appContext = React.createContext();

const AppProvider = props => {
  return (
    <appContext.Provider
      value={{
        appConfigs,
      }}
    >
      {props.children}
    </appContext.Provider>
  );
};

export default ({element}) => <AppProvider>{element}</AppProvider>;
