const metaData = {
  SITE_NAME: `QUTENZA Patient Cost Savings Program`,
  SITE_DEFAULT_DESCRIPTION: `QUTENZA Patient Cost Savings Program`,
  SITE_DEFAULT_KEYWORDS: ['QUTENZA'],
  PATIENT_JOB_CODE: `M-QZA-US-01-22-0022  February 2022`,
  HCP_JOB_CODE: `XX-XXXX/March 2020`,
  CURRENT_YEAR: new Date().getFullYear(),
};

/**
 * HEADER_TYPE : fixed or regulars
 * ISI_OPTIONS["TYPE"] : sticky or regular
 */
const siteOptions = {
  INDICATIONS: ['hcp', 'patient'],
  HEADER_TYPE: 'fixed',
  ISI_OPTIONS: {
    TYPE: 'sticky',
    STICKY_STYLES: {
      backgroundColor: 'white',
      height: 150,
      width: '100%',
      boxShadow: '-1px -5px 15px 0px rgba(0,0,0,0.10)',
      paddingTop: 5,
      zIndex: 10,
    },
  },
};

const appConfigs = {
  metaData: metaData,
  siteOptions: siteOptions,
};

export default appConfigs;
